.main-nav{
    border-bottom: 1px dotted var(--analy-color);
    border-radius: 0px;
    background-color: var(--bg-color-2);
    display: flex;
    justify-content: space-between;
    padding:10px;
}
.logo-nav{
    width:30px;
    height: auto;
}
.nav-div-1{
    width:auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-div-2{
    display: flex;
    align-items: center;
}
.link{
    margin-left: 15px;
    font-weight: bold;
    text-decoration: none;
    color:black;
    font-size: 14px;
}