* {
  font-family: "Raleway", sans-serif;
}
:root {
  --bg-color: #dffcff;
}
.ticketinfo-main {
  margin: 20px !important;
}
.label-div {
  padding: 5px;
  border-bottom: 1px dotted #0075bd;
  display: flex;
  font-weight: 600 !important;
}
.severity-0 {
  display: flex;
  align-items: center;
  color: yellow;
}
.severity-1 {
  display: flex;
  align-items: center;
  color: orange;
}
.severity-2 {
  display: flex;
  align-items: center;
  color: red;
}
.header-div {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dotted #0075bd;
}
.header-div-1 {
  display: flex;
  justify-content: space-between;
}
.header-div {
  padding: 0;
}
.div-1-header {
  margin-right: 2em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  display: inline-block;
  vertical-align: top;
}
.div-1-label {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
}
.div-1-value {
  font-size: 12px;
}
.other-versions {
  padding: 0;
  float: left !important;
  width: 100%;
  display: flex;
}
.other-version-label {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  font-style: normal;
  color: #000 !important;
  display: flex;
  align-items: center;
}
.version {
  margin: 5px;
  padding: 0.2em;
  background-position-x: 0;
  background-position-y: 0;
  background-size: auto;
  background-size: 100% 50%;
  background-position: bottom;
  color: #0075bd;
  display: inline-flex;
}
.heading {
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  margin-right: 10px;
  word-break: break-all;
}
.div-2-class {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  font-family: "Raleway", sans-serif;
  margin: 20px;
}
.div-2-class button {
  border: none;
  outline: none;
  background: none;
  font-weight: 600;
  cursor: pointer;
  color: #0075bd;
}
.next-button {
  margin-left: auto;
}
.div-3 {
  background-color: var(--bg-color);
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
}
.div-3-div-1 {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  text-transform: uppercase;
}
.div-3-div-2 {
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  word-wrap: break-word;
  white-space: pre-line;
  padding: 2px;
  padding-left: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: initial;
}
.coloured-component {
  color: #0075bd;
}
.div-3-div-4 {
  font-weight: bold;
}
.div-3-div-5-p {
}
.div-3-div-5 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.div-3-div-6-p {
}
.div-3-div-6-table {
  background-color: #ffffff;
  border: 1px dotted #0075bd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.param-div {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.div-3-div-6-table-cont {
  display: flex;
}
.column-head {
  padding-left: 20px;
  width: 15vw;
  font-size: 10px;
  font-weight: 400;
}
.column-body {
  width: 25vw;
  font-size: 13px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.table-container {
  display: flex;
  flex-direction: column;
}
.table-row {
  display: flex;
  width: 100%;
}
.table-cell {
  flex: 1;
  min-width: 100px;
  padding: 8px;
}
.key-value-groups {
  width: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  gap: 10px;
}

.key-value-group {
  flex-flow: column wrap;
}
.group {
  display: flex;
  justify-content: space-between;
}
.text-wrap {
  white-space: pre-line;
}
.side-notes{
  font-size: 14px;
}
.activity-label{
  display: none;
}
hr {
  border: none;
  border-top: 1px solid #d8d8d8;
}
@media screen and (max-width: 800px) {
  .header-div-1 {
    display: block;
  }
  .key-value-groups {
    white-space: pre-line;
  }
  .link {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .other-versions {
    display: block;
  }
  .table-row {
    display: block;
  }
  .column-body pre{
    padding-left: 10px;
    overflow-wrap: break-word;
  }
  .div-3-div-6-table{
    word-break: break-all;
  }
  .side-notes{
    font-size: 10px;
  }
  .stack-div{
    flex-direction: column;
    align-items: start !important;
  }
  .graph-item{
    margin-bottom: 5px;
  }
  .div-3-div-5-p{
    word-break:break-all;
  }
  .column-head{
    padding-left: 0px;
  }
  .column-body{
    word-wrap: break-word;
    font-size: 10px;
  }
  .table-cell{
    display: block;
  }
  .activity-hide{
    display: none;
  }
  .activity-label{
    display:block;
  }
}
@media screen and (max-width: 400px) {
  * {
  }
  .key-value-groups {
    display: block;
  }
}
@media screen and (max-width: 300px) {
  .graph-item {
    height: 10px !important;
  }
  .table-row {
    word-wrap: break-word;
    white-space: pre-line;
  }
}
@media screen and (max-width:1000px) {
  .table-cell{
    word-break: break-all;
  }
}