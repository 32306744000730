.graph-div{
    width: auto;
    margin-right: 2em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    display: inline-block !important;
    vertical-align: top !important; 
}
.graph-title{
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
}
.bar-graph{
    margin-bottom: 10px;
    padding: 0.5px;  
    display: flex;
    flex-direction: column;
    align-items: space-between;
}
.graph-item {
    margin-right: 3px;
    height: 15px;
    clear: both;
    float: left;
    padding-right: 5px;
}
.os-version-class{
    background-color: #aec8ff;
}
.device-class{
    background-color: #e1aeff;
}
.country-class{
    background-color: #d2ffae;
}
.graph-label{
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
}