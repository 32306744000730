:root {
  --bg-color: #dffcff;
  --border: #0075bd;
  --severity-color: yellow;
  --border-second: #8aeaea;
  --bg-color-2: #f9f6ed;
  --analy-color: #c67e0e;
}

a, .pointer {
  cursor:  pointer !important;
}
.whole {
  overflow-x: hidden;
  margin: 10px;
}
.main {
  background-color: var(--bg-color);
  padding: 15px;
  border-radius: 5px;
  border: solid 1px;
  border-color: var(--border);
  text-align: center;
  background-color: var(--bg-color);
  padding: 15px;
  border-radius: 5px;
  border: solid 1px;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-color: var(--border);
  text-align: center;
}
.label {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 10px;
  color: #636363;
  font-family: "Raleway", sans-serif;
}
.select-label {
  background-color: white;
  border: thin solid var(--border-second);
  border-radius: 2px;
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  outline: none;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-image: linear-gradient(45deg, transparent 50%, #0075bd 50%), linear-gradient(135deg, #0075bd 50%, transparent 50%), linear-gradient(to right, #8aeaea, #8aeaea);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-radius: 5px;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.select-label button {
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
}
.app-list {
  padding: 10px;
}
.analytics-div {
  margin: 0;
  background-color: var(--bg-color-2);
  padding: 15px;
  border-radius: 5px;
  border: dotted 1px;
  border-color: var(--analy-color);
  margin-top: 10px;
}
.analytics-btn {
  color: var(--analy-color);
  border-color: var(--analy-color);
  border: dotted 1px !important;
  background-color: #fff;
  border-color: var(--border-second);
  border-radius: 5px !important;
  height: 45px !important;
  font-size: 14px !important;
  line-height: inherit !important;
  float: right !important;
  font-family: "Raleway", sans-serif;
  padding: 6px 12px;
  font-weight: 400px;
}
.analytics-btn warn {
  color: blue;
}
.analy-div {
  background-color: var(--bg-color-2);
  margin-top: 10px;
}
#installation-count {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  color: #000 !important;
  font-size: 14px;
  line-height: 1.8;
  text-align: left;
}
#installation-count b {
  font-weight: bold;
}
.percentage-div {
  margin-right: 2em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  display: inline-block;
  vertical-align: top;
}
.percentage-text {
  font-weight: 600 !important;
  margin-bottom: 20px !important;
  line-height: 1.5;
  font-size: 16px;
  box-sizing: border-box;
  color: #484848 !important;
  font-family: inherit;
}
.percentage-text::before {
  content: ">>> ";
  font-weight: 600 !important;
  color: #50cccc;
}
.graph-div2 {
  display: "flex";
  align-items: "center";
  margin-bottom: "10px";
  flex-direction: column;
  height: auto;
}
.graph-label {
  margin-right: 3px;
  height: 15px;
  clear: both;
  padding-right: 5px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
}
.graph-graph {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}
.ticketlist-table {
  width: 100%;
}
.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
}

.row {
  display: flex;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e5faf8;
  padding: 10px;
}

.cell {
  flex: 1;
  padding: 8px;
  text-align: left;
}
.table-div {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: hidden;
  margin: 10px;
  margin-top: 40px;
}

.red-label {
  font-size: 10px;
  color: red;
  display: flex;
  align-items: center;
}
.header-row {
  display: flex;
  width: 100%;
  height: auto;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 600 !important;
}

.header-row .cell {
  text-align: left;
}
.ticket-cell {
  flex: 3;
}
.unsort {
  color: var(--border);
}
.cell warn {
  color: blue;
}
.ticket {
  margin-right: 10px;
}
.link-row {
  text-decoration: none;
  color: #000 !important;
  font-family: "Raleway", sans-serif;
}
.link-row:hover {
  background-color: var(--bg-color);
}
.analy-main {
  margin: 0;
}
.severity-0 {
  display: flex;
  align-items: center;
  color: yellow;
}
.severity-1 {
  display: flex;
  align-items: center;
  color: orange;
}
.severity-2 {
  display: flex;
  align-items: center;
  color: red;
}
@media screen and (max-width: 800px) {
  .label {
    font-size: 8px;
    text-align: left;
  }
  .select-label {
    font-size: 12px;
    display: block;
    background-position: calc(100% - 11px) calc(1em + 2px), calc(100% - 6px) calc(1em + 2px), 100% 0;
  }
  .analytics-btn {
    height: 30px !important;
    font-size: 12px !important;
  }
  .table-div {
    width: auto;
    padding-left: 0;
  }
  .cell {
    font-size: 12px;
    word-break: break-all;
  }
}
@media screen and (max-width: 500px) {
  .cell {
    font-size: 10px;
  }
  .percentage-text{
    font-size: 12px;
  }
  .graph-div2{
    font-size: 12px;
  }
  
}
@media screen and (max-width: 600px) {
  .select-label {
    font-size: 10px;
  }
  .label {
    font-size: 6px;
  }
  .main{
    display: flex;
    flex-direction: column;
  }
}
